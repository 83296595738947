import React from 'react';

import logo from './missile.png';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import { useCallback } from "react";

const Home = () => {
  return (
    <div>
      {BackgroundMissiles()}
      {Introduction()}
    </div>
  );
}

function BackgroundMissiles() {
  const particlesInit = useCallback(async (engine) => {
    //console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        //console.log(container);
    }, []);

    return (
      <Particles className="particles-js" init={particlesInit} loaded={particlesLoaded} withDefaults={true} params={{ particles: { 
        number: { value: 20, density: { enable: true, value_area: 1000} },
        color: {value: '#ffffff'},
        size: {value: 16},
        opacity: {value: 1, random: false, anim: {enable: false}},
        move: {enable: true, speed: 1, direction: 'bottom', out_mode: 'out', bounce: false},
        line_linked: {enable: false},
        shape: {
          type: 'image',
          image: { src: logo, width: 39, height: 123 }
        },
        rotate: {path: true, value: 90}
      }}}
      />
    );
  }
  
  function Introduction() {
    return (
      <div className="App">
        <header>
          <img src={logo} className="App-logo" alt="logo" />
          <h1>We Got Missiles Commander</h1>
          <a id="googleplay" href="https://play.google.com/store/apps/details?id=com.Kellarikoodari.WeGotMissilesCommander">Google Play</a>
          <a id="privacy-policy" href={process.env.PUBLIC_URL + "/privacy-policy.html"}>Privacy Policy</a>
          <a id="terms" href={process.env.PUBLIC_URL + "/terms-and-conditions.html"}>Terms and conditions</a>
        </header>
        <main>
          <div>
            <p>
              The development for this game started in 2014 with Unity 4. The work has not been constant since then. To be honest there were long periods of time when nothing was added, and the game was even thought to be abandoned. But as I grew older I started to get the urge to create something of my own.
            </p>
            <p>
              Just like in the classical Missile Command arcade games, you compete with your survival high score. Each level potentially has a possibility for an infinite score, but it is going to be capped by your ability to concentrate and to utilize the small game mechanics... which of course just means the physics engine.
            </p>
            <p>
              Each of the six levels, plus the secret wildcard level, is different enough to encourage different playstyles. Feel free to experiment how they feel and choose your favorites. Then get good with all them. If you just blindly shoot your missiles you are not going to get very far.
            </p>
            <p>
              Play the game your way. Besides the occasional ads there is nothing that prevents you from playing or from not playing. Games should be simple and hinder the player as little as possible and provide minimal distractions while giving interaction as much as possible.
            </p>
        </div>
      </main>
    </div>
  );
}
 
export default Home;